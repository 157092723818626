<template>
  <section>
    <div class="row">
      <div
        id="col1"
        class="col-lg-6"
        style="margin-bottom: 10px;"
      >
        <row>
          <div id="page_1">
            <div id="id1_1">
              <p
                class="p0 ft0"
                style="text-align: center; margin-top: 10px"
              >
                SOCIEDADE NORTE E NORDESTE DE
                OFTALMOLOGIA
              </p>
              <p
                class="p1 ft0"
                style="text-align: center"
              >
                Estatuto Social da Sociedade
                Norte e Nordeste de Oftalmologia
                – SNNO
              </p>
              <p class="p2 ft0">
                Capítulo I
              </p>
              <p
                class="p3 ft0"
              >
                Denominação, Qualificação,
                Duração, Sede e Finalidade
              </p>
              <p
                class="p4 ft2"
              >
                <span
                  class="ft1"
                >Art. 1º. </span>A SOCIEDADE NORTE E NORDESTE DE
                OFTALMOLOGIA – SNNO, inscrita no
                CNpJ sob o nº
                <nobr>13.286.314/0001-44,</nobr>
                fundada em 1º de março de 1963,
                com seu Estatuto registrado no
                Cartório do primeiro Registro de
                pessoas Jurídicas de Recife/pE,
                no Livro nº
                <span
                  class="ft1"
                >&lt;&lt;&gt;&gt;, </span>sob o nº
                <span
                  class="ft1"
                >&lt;&lt;&gt;&gt; </span>de ordem de
                <span
                  class="ft1"
                >&lt;&lt;&gt;&gt;</span>, com sede e foro em Recife,
                capital do Estado de pernambuco,
                é uma sociedade civil de direito
                privado sem fins lucrativos,
                cujo tempo de duração é
                indeterminado, voltada para a
                associação dirigida ao ensino,
                pesquisa científica,
                desenvolvimento tecnológico e
                cultural de médicos
                oftalmologistas, bem como de
                preservação da saúde da
                população dos estados
                abrangidos, regida pelo presente
                Estatuto e pelo Regimento
                Interno – RI, que tem por
                finalidade congregar os médicos
                oftalmologistas do norte e
                nordeste do Brasil, atuando,
                ainda, como órgão máximo da
                Oftalmologia no Norte e Nordeste
                do Brasil, incluindo entre suas
                finalidades institucionais:
              </p>
              <p
                class="p5 ft2"
              >
                I - representar a Oftalmologia
                do Norte e Nordeste do Brasil
                junto aos órgãos governamentais,
                bem como indicar delegados junto
                às organizações municipais,
                estaduais nacionais e
                internacionais, nos assuntos
                pertinentes à Oftalmologia;
              </p>
              <p
                class="p6 ft2"
              >
                <span class="ft3">II</span><span
                  class="ft4"
                >- zelar pelo respeito à
                  ética profissional e pela
                  eficiência </span><nobr>
                  técnico-profissional
                </nobr>
                do oftalmologista – cidadão e
                médico, tendo por base elevados
                preceitos sociais e morais;
              </p>
              <p
                class="p7 ft2"
              >
                III - resguardar o exercício da
                Oftalmologia e representar os
                oftalmologistas do Norte e
                Nordeste do Brasil na defesa de
                seus direitos profissionais,
                sociais e econômicos;
              </p>
              <p
                class="p7 ft2"
              >
                IV - contribuir para elevar o
                nível técnico e científico da
                Oftalmologia do Norte e Nordeste
                do Brasil, mantendo intercâmbio
                permanente com instituições
                congêneres do país e do
                exterior;
              </p>
              <p
                class="p8 ft2"
              >
                V - representar os interesses
                de seus associados judicial e
                extrajudicialmente,
                independentemente da outorga
                individual de poderes, em defesa
                de suas prerrogativas
                profissionais<span
                  class="ft5"
                >, </span>da saúde ocular da população e
                dos direitos dos consumidores,
                desde que tais interesses possam
                ser caracterizados como direitos
                coletivos e difusos e possam
                acarretar benefícios diretos ou
                indiretos para a classe
                oftalmológica do Norte e
                Nordeste do Brasil, podendo,
                inclusive, ajuizar Ações Civis
                públicas, bem como quaisquer
                outras nas esferas judicial e
                administrativa, sem prévia
                autorização do Conselho
                Deliberativo e da Assembleia de
                associados;
              </p>
              <p
                class="p7 ft2"
              >
                VI - colaborar com a melhoria
                do ensino da Oftalmologia nas
                Escolas Médicas e nos Cursos de
                <nobr>pós-graduação,</nobr>
                Especialização, Atualização,
                Aperfeiçoamento e Estágios;
              </p>
              <p
                class="p9 ft2"
              >
                VII - lutar para que a
                Oftalmologia, no Brasil, seja
                praticada por médicos portadores
                do Título de Especialista em
                oftalmologia, devidamente
                registrado nos Conselhos
                Regionais de Medicina;
              </p>
              <p
                class="p8 ft2"
              >
                VIII - propugnar pela
                obediência a esse Estatuto,
                cumprindo e fazendo cumprir o
                juramento de Hipócrates, à
                Declaração de princípios dos
                Oftalmologistas Brasileiros, ao
                Código de Ética Médica em vigor
                no país e aos estatutos e
                diretrizes do Conselho Federal
                de Medicina, da Associação
                Médica Brasileira e Conselho
                Brasileiro de Oftalmologia;
              </p>
              <p
                class="p8 ft2"
              >
                IX - incentivar a realização de
                estudos e pesquisas científica
                oftalmológica em geral, com o
                objetivo de desenvolver
                tecnologias alternativas,
                divulgando seus resultados para
                a sociedade e para classe
                médica;
              </p>
              <p
                class="p10 ft2"
              >
                X - motivar e envolver a
                Oftalmologia do Norte e Nordeste
                do Brasil em projetos destinados
                a promover a saúde ocular da
                população, incluindo campanhas
                de educação e de assistência
                oftalmológica;
              </p>
              <p
                class="p8 ft2"
              >
                XI - organizar e promover
                simpósios, congressos, feiras,
                projetos de melhoria da saúde
                ocular, atividades científicas e
                culturais e outros eventos de
                interesse da classe;
              </p>
              <p
                class="p11 ft3"
              >
                XII - promover o estudo e
                estimular o aperfeiçoamento da
                especialidade;
              </p>
              <p
                class="p12 ft2"
              >
                XIII - dar sua contribuição de
                forma gratuita, visando à
                redução de doenças oculares,
                colaborando para a recuperação
                ou reabilitação dos deficientes
                visuais;
              </p>
              <p
                class="p13 ft2"
              >
                XIV - congregar os
                oftalmologistas, estimulando as
                relações sociais e científicas,
                e
                <nobr>dar-lhes</nobr>
                assistência adequada; XV -
                promover reuniões ordinárias, de
                preferência em sua sede assim
                como as extraordinárias, quando
                necessárias;
              </p>
              <p
                class="p4 ft2"
              >
                XVI - promover campanhas
                educativas e
                <nobr>fazer-se</nobr> ouvir na
                organização de serviços e
                campanhas oftalmológicas nas
                Regiões Norte e Nordeste;
              </p>
              <p
                class="p11 ft3"
              >
                XVII - opinar sobre as
                atividades ligadas a saúde
                ocular e
                <nobr>monitorá-las.</nobr>
              </p>
              <p
                class="p12 ft2"
              >
                <span
                  class="ft6"
                >parágrafo único. </span>para cumprimento de suas
                finalidades, a SNNO poderá
                efetuar convênios, contratos,
                acordos e parcerias, receber
                doações ou subvenções de
                instituições públicas ou
                privadas, universidades de
                finalidade lucrativa ou não,
                assim como associações,
                autarquias e fundações.
              </p>
              <p
                class="p14 ft2"
              >
                <span class="ft6">Art. 2º</span>. A SNNO será constituída por
                número ilimitado de sócios e
                terá os seguintes poderes: I -
                Assembleia Geral;
              </p>
              <p
                class="p11 ft3"
              >
                II – Diretoria;
              </p>
              <p
                class="p15 ft2"
              >
                <span class="ft7">III</span><span
                  class="ft8"
                >- Conselho Deliberativo
                  composto por 05 (cinco)
                  membros; IV - Conselho
                  Fiscal composto por 03
                  (três) membros.</span>
              </p>
              <p
                class="p11 ft3"
              >
                <span
                  class="ft9"
                >parágrafo Único</span>. Os sócios da SNNO não
                respondem solidária, nem
                subsidiariamente pelas
                obrigações da Sociedade.
              </p>
            </div>
          </div>
          <div id="page_2">
            <div id="id2_1">
              <p class="p16 ft9">
                Capítulo II
              </p>
              <p
                class="p17 ft9"
              >
                Dos Associados, Direitos e
                Deveres
              </p>
              <p
                class="p18 ft2"
              >
                <span
                  class="ft6"
                >Art. 3º. </span>Os sócios da Sociedade Norte e
                Nordeste de Oftalmologia serão
                distribuídos em 07 (sete)
                categorias: I - Fundador;
              </p>
              <p class="p11 ft3">
                II - Titular;
              </p>
              <p
                class="p19 ft3"
              >
                III - Aspirante;
              </p>
              <p
                class="p20 ft2"
              >
                IV - Aspirante Aluno; V -
                Honorário;
              </p>
              <p
                class="p21 ft2"
              >
                VI - Benemérito; VII -
                Emérito.
              </p>
              <p
                class="p11 ft3"
              >
                <span
                  class="ft9"
                >Art. 4º. </span>Sócios Fundadores são aqueles
                que assinaram a ata de fundação
                da Sociedade Norte e Nordeste de
                Oftalmologia.
              </p>
              <p
                class="p12 ft2"
              >
                <span
                  class="ft6"
                >Art. 5º. </span>Sócios Titulares são os
                fundadores e os médicos
                portadores do título de
                Especialista em Oftalmologia
                conferido pelo Conselho
                Brasileiro de
                Oftalmologia/Associação Médica
                Brasileira (CBO/AMB) ou,
                certificado de residência médica
                conferido pelo Ministério da
                Educação e Cultura com devido
                registro nos respectivos
                Conselhos Regionais de Medicina
                do estado correspondente do
                associado.
              </p>
              <p
                class="p22 ft3"
              >
                <span
                  class="ft9"
                >Art. 6º. </span>Sócios Aspirantes são os
                portadores de diploma de médico
                que solicitarem inscrição ao
                quadro de associados.
              </p>
              <p
                class="p23 ft2"
              >
                <span class="ft9">§</span><span class="ft11">1º. </span>As propostas de admissão de
                sócios nas categorias: "titular"
                e "aspirante" deverão ser
                endossadas por 02 (dois) sócios
                titulares em situação
                regular.
              </p>
              <p
                class="p6 ft2"
              >
                <span class="ft9">§</span><span class="ft12">2º. </span>Serão ainda considerados sócios
                titulares os médicos não
                detentores do título de
                Especialista em oftalmologia,
                mas que comprovem exercer a
                especialidade a mais de 15
                (quinze) anos.
              </p>
              <p
                class="p8 ft2"
              >
                <span
                  class="ft6"
                >Art. 7º. </span>Sócios Aspirantes Alunos são os
                portadores de diploma de médico
                que estejam cursando Residência
                de Oftalmologia reconhecida pelo
                Ministério da Educação e Cultura
                (MEC) ou pelo Conselho
                Brasileiro de Oftalmologia ou
                que, não detentores do Título de
                Especialista registrado no
                Conselho Federal de Medicina,
                comprovem exercer a
                especialidade há mais de 03
                (três) anos.
              </p>
              <p
                class="p7 ft2"
              >
                <span
                  class="ft6"
                >Art. 8º. </span>O título de Sócio Honorário
                será conferido pela Diretoria a
                quem tenha contribuído, com
                mérito reconhecido, para o
                progresso da ciência ou o
                progresso da Oftalmologia do
                Norte e Nordeste do Brasil.
              </p>
              <p
                class="p7 ft2"
              >
                <span
                  class="ft6"
                >Art. 9º. </span>O título de Sócio Benemérito
                será outorgado pela diretoria
                àqueles que tenham serviços
                relevantes à Sociedade Norte e
                Nordeste de Oftalmologia.
              </p>
              <p
                class="p24 ft2"
              >
                <span
                  class="ft6"
                >parágrafo único. </span>As propostas para Sócio
                Honorário e Benemérito terão
                origem na diretoria ou
                requerimento firmado por 30
                (trinta) ou mais sócios
                titulares em pleno gozo de seus
                direitos.
              </p>
              <p
                class="p25 ft2"
              >
                <span
                  class="ft6"
                >Art. 10. </span>O Sócio Titular que passar 30
                (trinta) anos nesta condição
                passará automaticamente à
                categoria de Sócio Emérito,
                gozando de todos os direitos
                adquiridos e podendo optar,
                mediante requerimento, pelo não
                pagamento das anuidades.
              </p>
              <p
                class="p7 ft2"
              >
                <span
                  class="ft6"
                >Art. 11. </span>Os Sócios Titulares e
                Aspirantes serão obrigados ao
                pagamento de uma taxa anual cujo
                montante e forma de pagamento
                serão decididos pela Diretoria
                Executiva.
              </p>
              <p
                class="p24 ft2"
              >
                <span
                  class="ft6"
                >parágrafo Único. </span>Dos Sócios Aspirantes Alunos
                será cobrada anuidade
                correspondente à metade da
                anuidade cobrada dos Sócios
                Titulares e Aspirantes.
              </p>
              <p
                class="p7 ft2"
              >
                <span
                  class="ft6"
                >Art. 12. </span>Somente os Sócios Titulares
                terão direito a votar e serem
                votados na eleição da Diretoria
                Executiva e do Conselho Fiscal
                da SNNO, de acordo com as
                condições previstas no presente
                Estatuto.
              </p>
              <p
                class="p26 ft2"
              >
                <span
                  class="ft6"
                >Art. 13. </span>Cada sócio terá direito ao
                diploma assinado pelo presidente
                e Secretário Geral.
                <span
                  class="ft6"
                >Art. 14. </span>São direitos dos associados em
                dia com as obrigações
                estatutárias e regimentais: I -
                participar, com direito a voto,
                da Assembleia Geral da SNNO.
              </p>
              <p
                class="p11 ft3"
              >
                II - receber os informativos da
                SNNO;
              </p>
              <p
                class="p19 ft3"
              >
                III - publicar matérias nos
                periódicos da SNNO, desde que
                aprovadas pelo Conselho
                Editorial;
              </p>
              <p
                class="p27 ft2"
              >
                IV - participar, como
                palestrante, dos Congressos
                promovidos pela SNNO, quando
                convidado pela Comissão
                Científica da SNNO; V -
                participar das Comissões
                permanentes e Especiais, quando
                indicado pela diretoria;
              </p>
              <p
                class="p22 ft3"
              >
                VI - integrar o Conselho Fiscal
                quando for eleito para tal;
              </p>
              <p
                class="p28 ft2"
              >
                VII - receber orientações,
                diretrizes, cópias de documentos
                (comunicados, jurisprudência
                etc.), quando encaminhar
                consultas escritas ao Secretário
                Geral;
              </p>
              <p
                class="p29 ft2"
              >
                VIII - convocar a Assembleia
                Geral nos termos deste estatuto.
                <span
                  class="ft6"
                >Art. 15. </span>São deveres dos associados:
              </p>
              <p
                class="p30 ft2"
              >
                I - contribuir com a anuidade
                fixada pela Diretoria, exceto os
                maiores de setenta anos, aos
                quais fica facultada a
                contribuição voluntária; II -
                cumprir o disposto neste
                Estatuto e no RI - Regimento
                Interno;
              </p>
              <p
                class="p11 ft3"
              >
                III - zelar pelo bom nome e
                prestígio da SNNO e da
                Oftalmologia do Norte e Nordeste
                do Brasil;
              </p>
              <p
                class="p19 ft3"
              >
                IV - acatar os posicionamentos
                e resoluções da Diretoria da
                SNNO, aprovados pela Assembleia
                Geral e pelo Conselho
                Deliberativo.
              </p>
              <p
                class="p23 ft2"
              >
                <span class="ft9">§</span><span class="ft11">1º. </span>A
                qualificação de associado, bem
                como os direitos e deveres
                supramencionados, são
                intransmissíveis e, seja qual
                for sua categoria, não será
                titular de nenhuma quota ou
                fração ideal do patrimônio da
                entidade.
              </p>
              <p
                class="p6 ft14"
              >
                <span class="ft9">§</span><span class="ft13">2º. </span>É
                vedado ao associado ingressar
                com qualquer aporte patrimonial,
                salvo doações, sendo igualmente
                vedada qualquer restituição
                quando de sua eventual retirada
                do quadro de associados da
                SNNO.
              </p>
            </div>
          </div>
          <div id="page_3">
            <div id="id3_1">
              <p
                class="p11 ft3"
              >
                <span class="ft9">§</span><span class="ft15">3º. </span>Os associados não serão
                reembolsados por qualquer
                contribuição que realizaram ou
                que venham a realizar em favor
                da SNNO.
              </p>
              <p
                class="p31 ft2"
              >
                <span class="ft9">§</span><span class="ft11">4º. </span>A
                qualquer tempo o associado
                poderá, voluntariamente,
                declarando ou não os motivos,
                solicitar sua exclusão do quadro
                social por meio de
                correspondência endereçada ao
                Secretário Geral da entidade,
                encaminhada pelos correios ou
                protocolada na sede da SNNO.
                Concluído esse procedimento,
                <nobr>extinguem-se</nobr> de
                pronto, direitos e obrigações de
                ambas as partes.
              </p>
              <p
                class="p32 ft2"
              >
                <span class="ft9">§</span><span class="ft16">5º. </span>Serão excluídos do quadro de
                associados aqueles que,
                infringirem as disposições deste
                Estatuto e do RI - Regimento
                Interno, segundo decisão das
                Comissões de Ética e de Defesa
                profissional e anuência da
                Diretoria Executiva, nos termos
                do presente estatuto, respeitada
                a legislação em vigor. A
                Diretoria Executiva poderá optar
                por submeter essa decisão ao
                Conselho Regional de Medicina ao
                qual o infrator estiver
                inscrito, caso a penalidade
                também seja de competência
                daquele órgão.
              </p>
              <p
                class="p11 ft3"
              >
                <span class="ft9">§</span><span class="ft15">6º. </span>Na eventualidade do associado
                recorrer da exclusão, a decisão
                caberá à Assembleia Geral,
                respeitada a legislação em
                vigor.
              </p>
              <p
                class="p33 ft2"
              >
                <span
                  class="ft6"
                >Art. 16. </span>Os sócios da Sociedade poderão
                sofrer as seguintes penalidades,
                de acordo com atos inadequados e
                ilegais, assim considerados nos
                termos do RI - Regimento
                Interno:
              </p>
              <p
                class="p24 ft2"
              >
                I – advertência verbal, no caso
                de faltas leves, quando o
                culpado tomará ciência da
                punição por meio de comunicação
                verbal reservada, vedada a
                divulgação;
              </p>
              <p
                class="p22 ft3"
              >
                II – advertência escrita,
                aplicável aos reincidentes na
                penalidade de advertência verbal
                ou faltas consideradas de média
                gravidade;
              </p>
              <p
                class="p34 ft2"
              >
                <span class="ft7">III</span><span
                  class="ft17"
                >– suspensão, a que ficarão
                  sujeitos os reincidentes em
                  cominações de advertência
                  escrita ou autores de faltas
                  consideradas graves, os
                  quais terão seus direitos
                  suspensos de 6 meses a 1
                  ano;</span>
              </p>
              <p
                class="p7 ft2"
              >
                IV – exclusão, penalidade
                máxima, que será imposta aos
                reincidentes em faltas graves ou
                autores de faltas gravíssimas
                contra ética e o decoro pessoal
                ou profissional.
              </p>
              <p
                class="p24 ft2"
              >
                <span
                  class="ft6"
                >parágrafo único. </span>As sanções serão impostas
                segundo a natureza e a gravidade
                da falta e os elementos que
                individualizem a conduta
                punível.
              </p>
              <p
                class="p11 ft3"
              >
                <span
                  class="ft9"
                >Art. 17. </span>Serão excluídos da SNNO, os
                sócios que:
              </p>
              <p
                class="p35 ft2"
              >
                I - não contribuírem com a
                anuidade por 03 (três) anos
                consecutivos ou 05 (cinco) anos
                alternados; II - procederem de
                modo indigno na profissão
                <nobr>afastando-se</nobr> das
                normas do Código de Ética
                Médica;
              </p>
              <p
                class="p24 ft2"
              >
                III - atentarem contra a
                reputação ou a existência da
                Sociedade Norte e Nordeste de
                Oftalmologia ou das entidades a
                que esta for agregada;
              </p>
              <p
                class="p7 ft2"
              >
                IV - provocarem desordem,
                tumulto ou escândalo no recinto
                das sessões ou em qualquer
                dependência da sede social ou do
                local onde se estiver reunindo a
                Sociedade;
              </p>
              <p
                class="p11 ft3"
              >
                V – promovam a discórdia entre
                os membros da sociedade;
              </p>
              <p
                class="p36 ft2"
              >
                VI – deixem de apoiar a
                oftalmologia dos Estados do
                Norte e Nordeste do Brasil e
                brasileira, praticando atos
                atentatórios aos preceitos e
                finalidades buscados pela
                oftalmologia;
              </p>
              <p
                class="p11 ft3"
              >
                VII – que descumpram as normas
                estatutárias e do Regimento
                Interno da Sociedade;
              </p>
              <p
                class="p19 ft3"
              >
                VIII – que venham a ter o seu
                registro de médico cassado pelo
                Conselho Regional de
                Medicina.
              </p>
              <p
                class="p12 ft2"
              >
                <span
                  class="ft6"
                >Art. 18. </span>Os demais casos de eliminação
                previstos no art. 17 ocorrerão
                após a realização de processo
                administrativo disciplinar
                instaurado pela presidência da
                SNNO e conduzido, nos termos do
                RI - Regimento Interno, pela
                Comissão de Ética e Defesa
                profissional, sendo garantido ao
                acusado a ampla defesa e o
                contraditório.
              </p>
              <p
                class="p8 ft2"
              >
                <span class="ft6">§ 1º. </span>No curso das sindicâncias e
                processo administrativo
                disciplinares serão obedecidas
                as normas deste Estatuto e do
                Regimento Interno, havendo
                aplicação subsidiária das normas
                expedidas pelo Conselho Federal
                Medicina, com aplicação
                subsidiária, no que for cabível,
                da Lei Federal nº 9784, de 29 de
                janeiro de 1999.
              </p>
              <p
                class="p11 ft3"
              >
                <span class="ft9">§ 2º. </span>Da decisão da Comissão de Ética
                e Defesa profissional caberá
                recurso ao Conselho Deliberativo
                da SNNO.
              </p>
              <p
                class="p37 ft2"
              >
                <span class="ft6">§ 3º. </span>Os recursos serão interpostos
                dentro do prazo de 30 (trinta)
                dias, contado da publicação ou
                comunicação do ato ao
                interessado.
                <span
                  class="ft6"
                >Art. 19. </span>A readmissão do sócio eliminado
                será feita mediante requerimento
                do excluído com apresentação da
                prova de regularização da
                infração cometida, em sendo o
                caso.
              </p>
              <p
                class="p24 ft2"
              >
                <span
                  class="ft6"
                >Art. 20. </span>O sócio eliminado por qualquer
                motivo e que desejar regressar
                na Sociedade de Norte e Nordeste
                de Oftalmologia, deverá requerer
                à Diretoria, a reabertura do
                processo que motivou a sua
                exclusão oferecendo novas
                provas.
              </p>
              <p class="p38 ft9">
                Capítulo III
              </p>
              <p
                class="p39 ft9"
              >
                Órgãos Executivos,
                Deliberativos e
                Administrativos
              </p>
              <p
                class="p40 ft3"
              >
                <span
                  class="ft9"
                >Art. 21. </span>Os órgãos dirigentes do SNNO
                são:
              </p>
              <p
                class="p19 ft3"
              >
                I - Assembleia Geral;
              </p>
              <p
                class="p19 ft3"
              >
                II - Diretoria Executiva;
              </p>
              <p
                class="p41 ft2"
              >
                <span class="ft7">III</span><span
                  class="ft8"
                >- Conselho Deliberativo; IV
                  - Conselho Fiscal; e</span>
              </p>
              <p
                class="p11 ft3"
              >
                V - Comissões de Apoio.
              </p>
              <p class="p42 ft9">
                Seção I
              </p>
              <p
                class="p43 ft9"
              >
                Assembleia Geral
              </p>
              <p
                class="p28 ft14"
              >
                <span
                  class="ft1"
                >Art. 22. </span>O poder supremo da Sociedade
                Norte e Nordeste de Oftalmologia
                é representado pela Assembleia
                Geral que o exercerá de acordo
                com as normas do presente
                Estatuto e Regimento Interno.
              </p>
            </div>
          </div>
          <div id="page_4">
            <div id="id4_1">
              <p
                class="p44 ft2"
              >
                <span
                  class="ft6"
                >Art. 23. </span>Constituem a Assembleia Geral,
                portanto com direito a votar,
                todos os associados da SNNO no
                gozo de seus direitos.
                <span
                  class="ft6"
                >Art. 24. </span>Compete privativamente à
                Assembleia Geral:
              </p>
              <p
                class="p24 ft2"
              >
                I – eleger a Diretoria
                Executiva da SNNO de acordo com
                os critérios fixados no presente
                Estatuto, no RI - Regimento
                Interno e na legislação
                vigente.
              </p>
              <p
                class="p45 ft2"
              >
                <span class="ft3">II</span><span
                  class="ft4"
                >– destituir a Diretoria
                  Executiva da SNNO, a
                  destituição da Diretoria
                  Executiva, conjunta ou
                  individual, </span><nobr>dar-se-á</nobr> de acordo
                com a legislação vigente e com o
                RI - Regimento Interno todas as
                vezes que forem:
              </p>
              <p
                class="p11 ft3"
              >
                a) negligenciadas e
                desrespeitadas as finalidades da
                instituição e o disposto neste
                Estatuto e no RI - Regimento
                Interno;
              </p>
              <p
                class="p28 ft2"
              >
                b) constatadas atuações
                desonestas e improbidade
                administrativa, devidamente
                apurada em processo
                administrativo disciplinar, onde
                serão obedecidos os princípios
                constitucionais do Contraditório
                e da Ampla Defesa;
              </p>
              <p
                class="p7 ft2"
              >
                c) verificadas atitudes
                prejudiciais e comprovadamente
                contrárias aos interesses da
                Oftalmologia do Norte e Nordeste
                do Brasil e brasileira.
              </p>
              <p
                class="p24 ft2"
              >
                III – analisar e aprovar os
                balancetes apresentados pelo
                Tesoureiro e aprovados pelo
                Conselho Fiscal e pelo Conselho
                Deliberativo, conforme determina
                a legislação vigente;
              </p>
              <p
                class="p22 ft3"
              >
                IV – alterar o presente
                Estatuto;
              </p>
              <p
                class="p46 ft2"
              >
                V – deliberar quanto à
                dissolução da SNNO, conforme
                estabelece este Estatuto e a
                legislação vigente; VI –
                deliberar sobre os assuntos
                levados à sua pauta;
              </p>
              <p
                class="p11 ft3"
              >
                VII - decidir em última
                instância.
              </p>
              <p
                class="p12 ft2"
              >
                <span
                  class="ft6"
                >parágrafo único. </span>Em caso de destituição de
                Diretores nos termos do inciso
                II do
                <span class="ft18">caput </span>deste artigo, a Assembleia
                Geral, decidirá mediante voto da
                maioria absoluta (metade mais
                um) de todos os membros,
                <nobr>procedendo-se</nobr>
                imediata eleição do(s) novo(s)
                diretor(es) para ocupar o(s)
                cargo(s), apenas para
                complementação do mandato da
                Diretoria.
              </p>
              <p
                class="p24 ft2"
              >
                <span
                  class="ft6"
                >Art. 25. </span>A alteração estatutária<span
                  class="ft6"
                >, </span>a destituição da Diretoria
                Executiva e a extinção da pessoa
                jurídica SNNO exigem convocação
                extraordinária da Assembleia,
                conforme a legislação
                vigente.
              </p>
              <p
                class="p7 ft2"
              >
                <span class="ft6">§ 1º. </span>para aprovação das situações
                descritas no
                <span class="ft18">caput </span>é exigido o voto concorde de
                2/3 (dois terços) dos associados
                com direito a voto, presentes à
                Assembleia; não será admitida
                aprovação em primeira
                convocação, salvo se contar com
                maioria absoluta dos
                associados.
              </p>
              <p
                class="p47 ft2"
              >
                <span class="ft6">§ 2º. </span>Cada associado poderá votar uma
                única vez.
                <span class="ft6">§ 3º. </span>Não será admitido voto por
                procuração.
              </p>
              <p
                class="p8 ft2"
              >
                <span
                  class="ft6"
                >Art. 26. </span>para deliberar sobre a extinção
                da SNNO as decisões serão
                tomadas pelo voto concorde de
                2/3 (dois terços) dos presentes
                em Assembleia especialmente
                convocada, sendo exigida a
                presença da maioria absoluta dos
                associados com direito a voto em
                primeira convocação e 1/3 (um
                terço) nas demais
                convocações.
              </p>
              <p
                class="p4 ft2"
              >
                <span
                  class="ft6"
                >Art. 27. </span>Nos demais assuntos a
                Assembleia decidirá, em primeira
                convocação, com maioria absoluta
                dos presentes, em segunda
                convocação, meia hora após a
                primeira, com qualquer número,
                salvo os casos mencionados no
                artigo anterior, atendida a
                legislação vigente.
              </p>
              <p
                class="p7 ft2"
              >
                <span
                  class="ft6"
                >Art. 28. </span>As Assembleias terão início no
                horário estabelecido no Edital
                de convocação e serão presididas
                pelo presidente Executivo da
                SNNO e secretariadas pelo
                Secretário Geral. Na ausência
                destes a Assembleia elegerá um
                substituto.
              </p>
              <p
                class="p10 ft2"
              >
                <span
                  class="ft6"
                >Art. 29. </span>A Assembleia Geral Ordinária
                acontecerá, por ocasião dos
                Congressos promovidos pela SNNO
                para tratar dos assuntos
                constantes de sua pauta e será
                convocada pelo presidente com
                antecedência mínima de 30
                (trinta) dias por meio de edital
                afixado em sua sede ou enviado a
                todos associados via postal ou
                correio eletrônico.
              </p>
              <p
                class="p8 ft2"
              >
                <span
                  class="ft6"
                >Art. 30. </span>A Assembleia Geral
                Extraordinária, poderá ser
                convocada mediante Edital fixado
                na sede social da SNNO, ou
                enviado a todos os associados
                via postal ou correio
                eletrônico, com antecedência
                mínima de 10 (dez) dias, onde
                constará: local, dia, mês, ano e
                hora da primeira e da segunda
                chamada e ordem do dia, bem como
                o nome de quem a convocou,
                respeitada a legislação
                vigente.
              </p>
              <p
                class="p8 ft2"
              >
                <span
                  class="ft6"
                >Art. 31. </span>Assembleia Geral poderá ser
                convocada, extraordinariamente,
                pelo presidente Executivo da
                SNNO, pelo Conselho Deliberativo
                ou pelo Secretário Geral. É
                garantido aos associados, no
                gozo de seus direitos, a
                prerrogativa de
                <nobr>promovê-la,</nobr>
                respeitados os dispositivos
                legais vigentes.
              </p>
              <p
                class="p48 ft2"
              >
                <span class="ft6">§ 1º. </span>Ao assinar a lista de presença
                o associado deverá comprovar que
                está em dia com o pagamento da
                anuidade da SNNO.
                <span class="ft6">§ 2º. </span>De posse desta lista a
                Diretoria Executiva,
                providenciará a contagem dos
                presentes.
              </p>
              <p
                class="p7 ft2"
              >
                <span class="ft6">§ 3º. </span>Na Assembleia convocada pelos
                associados deverão estar
                presentes pelo menos 2/3 (dois
                terços) dos que convocaram, caso
                contrário esta não se
                realizará.
              </p>
              <p
                class="p7 ft2"
              >
                <span
                  class="ft6"
                >Art. 32. </span>No ano em que for realizada
                eleição a Assembleia Geral
                <nobr>reunir-se-á</nobr>
                ordinariamente no mês de
                novembro de acordo com o Edital
                de convocação firmado pelo
                presidente com a finalidade
                específica.
              </p>
              <p
                class="p8 ft2"
              >
                <span class="ft6">§ 1º. </span>O Edital de convocação supra
                aludido será publicado no site
                oficial da SNNO com antecedência
                mínima de 15 (quinze) dias. A
                juízo da Diretoria a referida
                convocação poderá ser feita
                através do site oficial da SNNO,
                correio eletrônico ou carta
                registrada obedecendo ao mesmo
                prazo.
              </p>
              <p
                class="p7 ft2"
              >
                <span class="ft6">§ 2º. </span>Se fato relevante impedir a
                realização da Assembleia
                Ordinária, o presidente deverá
                tomar todas as providências para
                que a mesma se realize no prazo
                máximo de 20 (vinte) dias.
              </p>
              <p
                class="p8 ft14"
              >
                <span
                  class="ft1"
                >Art. 33. </span>Extraordinariamente a
                Assembleia Geral será convocada
                pelo presidente quando julgar
                necessário ou a requerimento de
                20 (vinte) sócios titulares no
                gozo de seus direitos aos quais
                deverão se dirigir à Diretoria
                especificando por escrito o
                motivo da convocação.
              </p>
            </div>
          </div>
          <div id="page_5">
            <div id="id5_1">
              <p
                class="p10 ft2"
              >
                <span
                  class="ft6"
                >parágrafo Único. </span>Quando a Diretoria receber o
                pedido de Assembleia Geral
                Extraordinária, feito dentro das
                exigências estatutárias, marcará
                local e data para sua realização
                no prazo máximo de 10 (dez)
                dias.
              </p>
              <p
                class="p11 ft3"
              >
                <span
                  class="ft9"
                >Art. 34. </span>As Assembleias Gerais
                <nobr>ocupar-se-ão</nobr>
                exclusivamente dos fins para os
                quais foram convocados.
              </p>
              <p
                class="p12 ft2"
              >
                <span
                  class="ft6"
                >Art. 35. </span>O número mínimo de sócios
                titulares para constituição de
                uma Assembleia Geral
                Extraordinária em primeira
                convocação será de metade mais
                01 (um) dos sócios titulares em
                pleno gozo de seus direitos
                sociais. Em segunda convocação
                poderá ser qualquer número de
                sócios acima de 30 (trinta),
                sempre titulares,
                <nobr>iniciando-se</nobr> 30
                (trinta) minutos após a primeira
                convocação.
              </p>
              <p
                class="p8 ft2"
              >
                <span
                  class="ft6"
                >Art. 36. </span>A convocação para as
                Assembleias Gerais
                Extraordinárias será feita no
                site oficial da SNNO, bem como
                comunicado enviado por correio
                eletrônico
                <nobr>assegurando-se</nobr> o
                presidente que os sócios sejam
                notificados com antecedência
                mínima de 03 (três) dias.
              </p>
              <p
                class="p8 ft2"
              >
                <span
                  class="ft6"
                >parágrafo Único. </span>Os sócios requerentes da
                Assembleia Geral Extraordinária
                poderão optar por uma das
                modalidades de convocação acima
                mencionados referindo essa opção
                na solicitação e provisionando
                recursos julgados pela diretoria
                suficientes para cobrir as
                despesas dela decorrentes,
                juntamente com a entrada da
                petição.
              </p>
              <p
                class="p10 ft2"
              >
                <span
                  class="ft6"
                >Art. 37. </span>O associado terá que comprovar
                a sua adimplência para assinar a
                lista de presença que servirá de
                base para a verificação e
                comprovação do quórum.
              </p>
              <p class="p49 ft9">
                Seção II
              </p>
              <p
                class="p50 ft9"
              >
                Diretoria Executiva
              </p>
              <p
                class="p5 ft14"
              >
                <span
                  class="ft1"
                >Art. 38. </span>A Diretoria da Sociedade Norte
                e Nordeste de Oftalmologia será
                constituída por um presidente,
                um
                <nobr>Vice-presidente,</nobr> um
                Secretário Geral, um 1º e 2º
                Secretários e um 1º e 2º
                Tesoureiros e 4 (quatro)
                Representantes Regionais (sendo
                dois região Norte e dois da
                região Nordeste), eleitos pela
                Assembleia Geral para mandados
                de 02 (dois) anos, sendo vedada
                a reeleição para o mandato de
                presidente.
              </p>
              <p
                class="p51 ft2"
              >
                <span class="ft9">§</span><span class="ft11">1º. </span>Os integrantes da Diretoria
                Executiva têm poderes para
                contratar e cancelar contratos
                celebrados com a SNNO, contratar
                e demitir funcionários, bem como
                abrir e movimentar as contas
                bancárias da SNNO, sendo que nas
                relações com a rede bancária, a
                documentação – cheques, recibos,
                contratos e outros – deverão ser
                assinados, conjuntamente por
                dois diretores,
                indistintamente.
              </p>
              <p
                class="p6 ft2"
              >
                <span class="ft9">§</span><span class="ft16">2º. </span>A
                Diretoria poderá elaborar
                pareceres, comunicados e
                recomendações sobre assuntos
                afetos à Oftalmologia, para
                divulgar ou oficializar o
                posicionamento da SNNO e, se
                necessário,
                <nobr>assessorar-se-á</nobr> por
                uma ou várias Comissões de
                Apoio.
              </p>
              <p
                class="p52 ft2"
              >
                <span
                  class="ft6"
                >Art. 39. </span>A Diretoria será eleita pela
                Assembleia realizada no mês de
                novembro dos anos eleitorais
                para um mandato de 02 (dois)
                anos.
                <span
                  class="ft6"
                >parágrafo único. </span>A posse da Diretoria
                <nobr>dar-se-á</nobr> no máximo
                30 (trinta) dias após a
                eleição.
              </p>
              <p
                class="p11 ft3"
              >
                <span
                  class="ft9"
                >Art. 40. </span>Os membros da Diretoria não
                auferirão quaisquer vantagens
                financeiras pelo exercício de
                seus cargos.
              </p>
              <p
                class="p28 ft2"
              >
                <span
                  class="ft6"
                >Art. 41. </span>No caso de vacância definitiva
                de qualquer cargo da Diretoria
                os membros remanescentes
                elegerão o(s) substituto(s).
                Esta nomeação só se aplicará se
                o número de remanescentes
                corresponder a pelo menos 50%
                (cinquenta por cento) da
                Diretoria.
              </p>
              <p
                class="p25 ft2"
              >
                <span
                  class="ft6"
                >parágrafo único. </span>Não se observando o previsto no
                artigo anterior, será realizada
                nova Assembleia eleitoral,
                obrigatoriamente no prazo de 30
                (trinta) dias, obedecendo ao
                presente estatuto.
              </p>
              <p
                class="p53 ft2"
              >
                <span
                  class="ft6"
                >Art. 42. </span>Ao presidente compete, além do
                que consta no RI - Regimento
                Interno: I - dirigir as
                atividades da Sociedade de
                acordo com os estatutos;
              </p>
              <p
                class="p54 ft2"
              >
                II - representar a SNNO, quando
                necessário, em juízo ou fora
                dele, em suas relações oficiais
                com terceiros; III - assinar
                pela SNNO ou, quando necessário,
                fornecer procuração para que
                outra pessoa o faça;
              </p>
              <p
                class="p55 ft2"
              >
                IV - presidir as Sessões e
                Assembleias da Sociedade e as
                reuniões das Diretorias e
                Comissões de Apoio; V - chefiar
                as delegações da Sociedade;
              </p>
              <p
                class="p11 ft3"
              >
                VI - autorizar despesas e
                pagamentos;
              </p>
              <p
                class="p56 ft2"
              >
                VII - apresentar relatório das
                atividades da Sociedade durante
                a sua gestão, na sessão de posse
                da Diretoria que lhe suceder;
                VIII - convocar
                extraordinariamente a Assembleia
                Geral de associados em
                conformidade com este
                Estatuto;
              </p>
              <p
                class="p57 ft2"
              >
                IX – nomear os presidentes dos
                Congressos e Encontros
                Científicos promovidos pela SNNO
                em sua gestão; X - nomear,
                prioritariamente Associados
                Titulares, como integrantes das
                Comissões de Apoio.
              </p>
              <p
                class="p11 ft3"
              >
                <span
                  class="ft9"
                >Art. 43. </span>Ao
                <nobr>Vice-presidente</nobr>
                compete, além do que consta no
                RI - Regimento Interno:
              </p>
              <p
                class="p36 ft2"
              >
                I - colaborar com o presidente,
                <nobr>substituí-lo</nobr> em
                suas ausências ou impedimentos e
                ocupar seu cargo em caso de
                vacância, até o término do
                mandato;
              </p>
              <p
                class="p58 ft2"
              >
                <span class="ft3">II</span><span
                  class="ft8"
                >- substituir os demais
                  integrantes da Diretoria
                  (Secretário Geral, 1º
                  Secretário e Tesoureiro) em
                  suas ausências e
                  impedimentos; III -
                  acompanhar as atividades do
                  Secretário Geral, do 1º
                  Secretário e do Tesoureiro,
                  colaborando sempre que se
                  fizer necessário. </span><span
                  class="ft6"
                >Art. 44. </span>Ao Secretário Geral compete:
              </p>
              <p
                class="p8 ft2"
              >
                I - coordenar as relações da
                Sociedade com as congêneres
                nacionais, internacionais e em
                particular com o Conselho
                Brasileiro de Oftalmologia
                (CBO), as Associações Médicas de
                cada estado do Norte e Nordeste
                do Brasil, e Conselhos Regionais
                de Medicina do Norte e Nordeste
                do Brasil e a Sociedade
                Brasileira de Oftalmologia;
              </p>
              <p
                class="p11 ft3"
              >
                II - Dirigir os trabalhos da
                Secretaria Geral.
              </p>
              <p
                class="p59 ft2"
              >
                III - coordenar as relações com
                os sócios das Regionais; IV -
                responder pelo expediente da
                Sociedade;
              </p>
              <p
                class="p11 ft3"
              >
                V - substituir o
                <nobr>Vice-presidente</nobr> em
                seus impedimentos;
              </p>
              <p
                class="p19 ft3"
              >
                VI - desempenhar as tarefas
                conferidas pelo presente
                Estatuto ou atribuídas pela
                Diretoria;
              </p>
            </div>
          </div>
          <div id="page_6">
            <div id="id6_1">
              <p
                class="p7 ft2"
              >
                VII -
                <nobr>Responsabilizar-se</nobr>
                pela administração e
                funcionamento da Secretaria
                Geral, pelo patrimônio e
                funcionários da SNNO, incluindo
                contratações e demissões;
              </p>
              <p
                class="p11 ft3"
              >
                VIII - Substituir o presidente
                e o
                <nobr>Vice-presidente</nobr> em
                suas ausências e
                impedimentos;
              </p>
              <p
                class="p33 ft2"
              >
                IX - Em conjunto com a
                Diretoria, verificar se as
                chapas que concorrerão à eleição
                preenchem as condições
                estabelecidas neste Estatuto e
                no RI - Regimento Interno, dando
                ciência de seu resultado;
              </p>
              <p
                class="p11 ft3"
              >
                X - Receber, analisar e dar o
                devido encaminhamento às
                solicitações dos associados;
              </p>
              <p
                class="p28 ft2"
              >
                XI - Analisar e dar provimento
                aos pedidos de exclusão do
                quadro social eventualmente
                encaminhados à SNNO, em
                conformidade com este Estatuto e
                com a legislação vigente.
              </p>
              <p
                class="p11 ft3"
              >
                <span
                  class="ft9"
                >Art. 45. </span>Ao 1º Secretário compete:
              </p>
              <p
                class="p19 ft3"
              >
                I - redigir as atas das Sessões
                da Sociedade e das Reuniões da
                Diretoria;
              </p>
              <p
                class="p19 ft3"
              >
                II - substituir o Secretário
                Geral, em suas faltas e
                impedimentos e
                <nobr>auxiliá-lo</nobr> em suas
                tarefas;
              </p>
              <p
                class="p19 ft3"
              >
                III - colaborar com os demais
                Diretores, especialmente com o
                Secretário Geral, no desempenho
                de suas funções;
              </p>
              <p
                class="p60 ft2"
              >
                IV - secretariar as reuniões
                nas situações previstas nesse
                Estatuto e no RI - Regimento
                Interno e quando convocado pelo
                presidente.
                <span
                  class="ft6"
                >Art. 46. </span>Ao 2º Secretário compete:
              </p>
              <p
                class="p22 ft3"
              >
                I - organizar de acordo com os
                demais membros da Diretoria
                ordem do Dia das Sessões;
              </p>
              <p
                class="p19 ft3"
              >
                II - providenciar para que o
                local das sessões se encontre
                devidamente aparelhado e em
                ordem;
              </p>
              <p
                class="p23 ft2"
              >
                <span class="ft7">III</span><span
                  class="ft4"
                >- remeter a todos os sócios
                  titulares e aspirantes
                  comunicações referentes a
                  sessões ordinárias ou
                  extraordinárias ou </span><nobr>servir-se</nobr> de
                outros meios pelos quais consiga
                os mesmos fins.
              </p>
              <p
                class="p11 ft3"
              >
                <span
                  class="ft9"
                >Art. 47. </span>Ao 1º Tesoureiro compete:
              </p>
              <p
                class="p33 ft2"
              >
                I -
                <nobr>responsabilizar-se</nobr>
                pelo controle contábil da
                movimentação
                <nobr>
                  econômico-financeira
                </nobr>
                e pelos valores patrimoniais e
                obrigações da SNNO, mantendo
                arquivada na Secretaria Geral a
                documentação pertinente;
              </p>
              <p
                class="p61 ft2"
              >
                II - administrar os fundos e
                rendas da SNNO, conforme
                orientação da Diretoria e sob
                fiscalização do Conselho Fiscal;
                III - orientar a arrecadação da
                receita e a quitação das
                despesas previstas no
                orçamento;
              </p>
              <p
                class="p11 ft3"
              >
                IV - elaborar, em conjunto com
                a Diretoria, o orçamento
                semestral da gestão;
              </p>
              <p
                class="p19 ft3"
              >
                V - acompanhar e controlar a
                movimentação
                <nobr>
                  econômico-financeira
                </nobr>
                dos Congressos promovidos pela
                SNNO;
              </p>
              <p
                class="p33 ft2"
              >
                VI - prestar contas ao Conselho
                Fiscal, no prazo de 90 (noventa
                dias), contados a partir do
                encerramento de cada evento, do
                movimento financeiro dos
                mesmos;
              </p>
              <p
                class="p62 ft2"
              >
                VII - zelar pela execução
                atualizada dos serviços de
                contabilidade; VIII - promover,
                a cobrança das anuidades devidas
                pelos sócios; IX - apresentar à
                Diretoria balancetes
                trimestrais.
              </p>
              <p
                class="p22 ft3"
              >
                <span
                  class="ft9"
                >Art. 48. </span>Ao 2º Tesoureiro compete:
              </p>
              <p
                class="p63 ft2"
              >
                I - auxiliar o 1º Tesoureiro
                nas suas atividades; II -
                <nobr>substituí-lo</nobr> nos
                seus impedimentos.
              </p>
              <p
                class="p24 ft2"
              >
                <span
                  class="ft6"
                >Art. 49. </span>Aos Representantes Regionais
                compete representar a SNNO
                quando necessário, sob
                orientação do presidente,
                preservando a Ética sempre à luz
                do Estatuto.
              </p>
              <p
                class="p11 ft3"
              >
                <span
                  class="ft9"
                >Art. 50. </span>A Diretoria Executiva será
                assessorada pelas Comissões, por
                ele nomeadas.
              </p>
              <p
                class="p19 ft3"
              >
                <span class="ft9">§ 1º. </span>A constituição das Comissões
                poderá ser modificada a qualquer
                momento a critério da Diretoria
                Executiva.
              </p>
              <p
                class="p37 ft14"
              >
                <span class="ft1">§ 2º. </span>A comissão de Ética e Defesa
                profissional será integrada
                exclusivamente por sócios
                titulares, em pleno exercício de
                seus direitos.
                <span
                  class="ft1"
                >Art. 51. </span>Os membros da Diretoria que
                faltarem a 03 (três) reuniões
                consecutivas desse órgão, sem
                justificativa, perderão seus
                mandatos.
              </p>
              <p class="p64 ft9">
                Seção III
              </p>
              <p
                class="p65 ft9"
              >
                Conselho Deliberativo
              </p>
              <p
                class="p28 ft2"
              >
                <span
                  class="ft6"
                >Art. 52. </span>O Conselho Deliberativo será
                constituído pelos 05 (cinco)
                últimos
                <nobr>Ex-presidentes</nobr> da
                Sociedade Norte e Nordeste de
                Oftalmologia, os quais
                escolherão, entre si, através de
                votação secreta, o seu
                Coordenador e
                <nobr>
                  Vice-Coordenador.
                </nobr>
              </p>
              <p
                class="p9 ft2"
              >
                <span
                  class="ft6"
                >Art. 53. </span>O Conselho Deliberativo
                constitui órgão de consulta e
                instância deliberativa da
                Associação, sendo composto pelos
                05 (cinco) últimos
                <nobr>ex-presidentes.</nobr>
              </p>
              <p
                class="p11 ft3"
              >
                <span class="ft9">§</span><span class="ft15">1º. </span>A
                investidura dos seus membros é
                automática, no momento em que o
                presidente da Associação
                transmite o cargo ao seu
                sucessor.
              </p>
              <p
                class="p19 ft3"
              >
                <span class="ft9">§</span><span class="ft15">2º. </span>O
                Mandato do Conselho Deliberativo
                coincidirá com o da diretoria da
                Sociedade Norte e Nordeste de
                Oftalmologia.
              </p>
              <p
                class="p19 ft3"
              >
                <span class="ft9">§</span><span class="ft15">3º. </span>O
                Coordenador do Conselho
                Deliberativo será substituído
                nas suas faltas e impedimentos
                pelo
                <nobr>
                  Vice-Coordenador.
                </nobr>
              </p>
              <p
                class="p34 ft2"
              >
                <span class="ft9">§</span><span class="ft11">4º. </span>Os membros do Conselho
                Deliberativo não poderão fazer
                parte, concomitantemente, do
                mencionado conselho e da
                Diretoria ou do Conselho Fiscal
                da SNNO, podendo, entretanto,
                serem membros das Comissões de
                Apoio da SNNO.
              </p>
              <p
                class="p11 ft3"
              >
                <span
                  class="ft9"
                >Art. 54. </span>Compete ao Conselho
                Deliberativo:
              </p>
              <p
                class="p28 ft2"
              >
                I - deliberar em conjunto com a
                Diretoria, sobre as propostas de
                admissão de sócios, verificando
                se os candidatos preenchem as
                exigências estatutárias e
                regimentais, solicitando os
                documentos complementares
                julgados necessários;
              </p>
              <p
                class="p7 ft14"
              >
                II - deliberar sobre os
                assuntos da Ordem do Dia, ou
                sobre outros temas cuja inclusão
                for aprovada pela maioria dos
                Conselheiros presentes.
              </p>
            </div>
          </div>
          <div id="page_7">
            <div id="id7_1">
              <p
                class="p66 ft2"
              >
                <span class="ft7">III</span><span
                  class="ft8"
                >- julgar recursos que lhe
                  sejam dirigidos pelos demais
                  órgãos, nos casos previstos
                  o Estatuto e RI - Regimento
                  Interno. IV - homologar as
                  decisões da Comissão de
                  Ética e Defesa profissional
                  relativas a infrações
                  éticas;</span>
              </p>
              <p
                class="p67 ft2"
              >
                V - deliberar sobre os recursos
                interpostos de atos da
                Diretoria, nos casos previstos
                no RI - Regimento Interno; VI -
                apreciar matéria cujo exame foi
                convocado;
              </p>
              <p
                class="p11 ft3"
              >
                VII - emitir relatórios e
                pareceres;
              </p>
              <p
                class="p19 ft3"
              >
                VIII - assistir a diretoria, no
                trato de assuntos inerentes à
                associação;
              </p>
              <p
                class="p19 ft3"
              >
                IX - cumprir e fazer cumprir o
                presente estatuto e RI -
                Regimento Interno;
              </p>
              <p
                class="p68 ft2"
              >
                X - analisar as propostas de
                alteração do Estatuto e
                <nobr>encaminhá-las</nobr> à
                Assembleia Geral; XI - organizar
                o RI - Regimento Interno da
                SNNO, juntamente com a Diretoria
                Executiva; XII - deliberar e
                interpretar sobre os casos
                omissos e duvidosos deste
                Estatuto;
              </p>
              <p
                class="p69 ft2"
              >
                XIII - propor metas, linhas de
                planejamentos e estratégias de
                execução a serem implementadas
                pela Diretoria Executiva; XIV -
                supervisionar as aplicações
                dispostas no inciso anterior;
              </p>
              <p
                class="p7 ft2"
              >
                XV - respaldar a Diretoria
                Executiva nas decisões de
                alienar, adquirir e vender os
                imóveis de propriedade da SNNO,
                em conformidade com este
                Estatuto.
              </p>
              <p
                class="p70 ft2"
              >
                <span
                  class="ft6"
                >Art. 55. </span>O Conselho Deliberativo
                <nobr>reunir-se-á</nobr>
                ordinária e obrigatoriamente uma
                vez por ano, durante o Congresso
                Norte e Nordeste de Oftalmologia
                e, extraordinariamente, quantas
                vezes forem necessárias,
                mediante convocação do
                presidente da SNNO ou de no
                mínimo 1/3 (um terço) dos
                Conselheiros<span
                  class="ft18"
                >, </span>no gozo de seus direitos.
              </p>
              <p
                class="p11 ft3"
              >
                <span class="ft9">§ 1º. </span>As reuniões ordinárias do
                Conselho Deliberativo, por
                estarem vinculadas aos
                Congressos, estão dispensadas de
                convocação.
              </p>
              <p
                class="p28 ft2"
              >
                <span class="ft6">§ 2º. </span>As reuniões ordinárias do
                Conselho Deliberativo não
                poderão coincidir com as
                atividades científicas do evento
                nem com o processo eleitoral da
                SNNO.
              </p>
              <p
                class="p11 ft3"
              >
                <span class="ft9">§ 3º. </span>Não é permitido voto por
                procuração e, independente dos
                cargos ocupados, cada
                Conselheiro poderá votar uma
                única vez.
              </p>
              <p
                class="p28 ft2"
              >
                <span class="ft6">§ 4º. </span>A critério de seu Coordenador,
                o Conselho Deliberativo poderá
                se reunir fora da sede,
                obedecidas as normas
                estabelecidas pelo seu RI -
                Regime Interno.
              </p>
              <p
                class="p11 ft3"
              >
                <span class="ft9">§ 5º. </span>De todas as reuniões e
                deliberações do Conselho
                Deliberativo serão lavradas
                atas, registradas em livro
                próprio.
              </p>
              <p
                class="p12 ft2"
              >
                <span
                  class="ft6"
                >Art. 56. </span>Como item permanente da pauta
                das reuniões ordinárias, o
                Tesoureiro da SNNO submeterá aos
                presentes a aprovação do balanço
                contábil de 31 de dezembro do
                ano imediatamente anterior,
                acompanhado de parecer do
                Conselho Fiscal, que serão
                posteriormente submetidos à
                Assembleia Geral. Na mesma
                ocasião o Tesoureiro apresentará
                o balancete levantado no dia 31
                de julho imediatamente
                anterior.
              </p>
              <p
                class="p70 ft2"
              >
                <span
                  class="ft6"
                >Art. 57. </span>As reuniões extraordinárias
                serão convocadas com, no mínimo
                5 (cinco) dias de antecedência,
                por edital publicado pela SNNO
                ou por comunicado enviado por
                meio eletrônico aos Conselheiros
                e demais formas de comunicação
                eletrônica, contendo a pauta dos
                assuntos a serem tratados.
              </p>
              <p
                class="p24 ft2"
              >
                <span
                  class="ft6"
                >Art. 58. </span>As reuniões do Conselho
                Deliberativo serão presididas
                pelo presidente da SNNO, em seu
                impedimento pelo
                <nobr>Vice-presidente</nobr> ou
                pelo Secretário Geral. Na
                ausência ou impedimento destes,
                por um Conselheiro, eleito na
                ocasião por seus pares.
              </p>
              <p
                class="p11 ft3"
              >
                <span
                  class="ft9"
                >parágrafo único</span>. O presidente da SNNO não terá
                direito a voto nas deliberações
                do Conselho Deliberativo.
              </p>
              <p
                class="p33 ft2"
              >
                <span
                  class="ft6"
                >Art. 59. </span>As reuniões do Conselho
                Deliberativo serão secretariadas
                pelo 1º Secretário e em caso de
                ausência ou impedimento por um
                Conselheiro nomeado pelo
                presidente da sessão.
              </p>
              <p
                class="p8 ft2"
              >
                <span
                  class="ft6"
                >Art. 60. </span>O quórum para a realização das
                reuniões ordinárias e
                extraordinárias do Conselho
                Deliberativo será a maioria
                absoluta dos Conselheiros em
                primeira convocação e qualquer
                número em segunda, a
                <nobr>realizar-se</nobr> quinze
                minutos depois. Serão
                consideradas aprovadas as
                decisões que contarem com o
                apoio da maioria simples dos
                Conselheiros presentes.
              </p>
              <p class="p71 ft9">
                Seção IV
              </p>
              <p
                class="p72 ft9"
              >
                Conselho Fiscal
              </p>
              <p
                class="p28 ft2"
              >
                <span class="ft6">Art. 61</span>. O Conselho Fiscal, eleito
                juntamente com a Diretoria
                Executiva, é composto de 03
                (três) membros efetivos e um
                suplente, possui mandato igual
                ao da Diretoria e tem por
                finalidade:
              </p>
              <p
                class="p22 ft3"
              >
                I - apreciar todos os assuntos
                ligados ao patrimônio, bens,
                rendas, fundos e demais aspectos
                financeiros e econômicos da
                SNNO;
              </p>
              <p
                class="p23 ft2"
              >
                <span class="ft3">II</span><span
                  class="ft19"
                >- emitir pareceres sobre os
                  relatórios </span><nobr>
                  econômico-financeiros
                </nobr>
                da Diretoria, em especial o
                balanço e o balancete contábil,
                para apreciação da Assembleia
                Geral;
              </p>
              <p
                class="p11 ft3"
              >
                III - exigir e analisar o
                parecer da auditoria contábil
                externa, quando necessário;
              </p>
              <p
                class="p19 ft3"
              >
                IV - aprovar, em conjunto com o
                Tesoureiro da SNNO, as contas
                dos Congressos que ocorrerem em
                sua gestão.
              </p>
              <p
                class="p73 ft2"
              >
                <span
                  class="ft6"
                >Art. 62. </span>O Conselho Fiscal se reunirá
                ordinariamente uma vez por ano,
                por ocasião do Congresso Norte e
                Nordeste de Oftalmologia e
                extraordinariamente, por
                convocação de um dos integrantes
                do Conselho Fiscal, da Diretoria
                Executiva ou do Conselho
                Deliberativo.
                <span
                  class="ft6"
                >parágrafo único. </span>As reuniões do Conselho Fiscal
                serão presididas pelo presidente
                da SNNO e secretariadas pelo 1º
                Secretário, ambos sem direito a
                voto.
              </p>
              <p class="p74 ft9">
                Seção V
              </p>
              <p
                class="p75 ft9"
              >
                Das Comissões de Apoio
              </p>
              <p
                class="p33 ft14"
              >
                <span
                  class="ft1"
                >Art. 63. </span>As Comissões de Apoio têm por
                finalidade assessorar a
                Diretoria da SNNO, além do que
                lhes atribui o RI - Regimento
                Interno. A escolha dos
                integrantes das Comissões é
                competência da Diretoria
                Executiva da SNNO.
              </p>
            </div>
          </div>
          <div id="page_8">
            <div id="id8_1">
              <p
                class="p6 ft2"
              >
                <span class="ft9">§</span><span class="ft20">1º. </span>Além das Comissões de Apoio
                permanentes, poderão ser
                instituídas Comissões Especiais,
                se extinguindo quando
                preenchidas as finalidades a que
                se destinam.
              </p>
              <p
                class="p45 ft2"
              >
                <span class="ft9">§</span><span class="ft20">2º. </span>As alterações do RI - Regimento
                Interno, pertinentes a uma
                determinada Comissão, deverão
                ser aprovadas por 2/3 (dois
                terços) dos integrantes da
                Diretoria Executiva e Conselho
                Deliberativo, em votação
                conjunta.
              </p>
              <p
                class="p11 ft3"
              >
                <span class="ft9">§</span><span class="ft15">3º. </span>As comissões acima referidas
                serão coordenadas por um de seus
                membros, eleito entre seus
                pares.
              </p>
              <p
                class="p19 ft3"
              >
                <span class="ft9">§</span><span class="ft15">4º. </span>A
                constituição das Comissões
                poderá ser modificada a qualquer
                momento a critério do presidente
                da SNNO.
              </p>
              <p
                class="p19 ft3"
              >
                <span class="ft9">§</span><span class="ft15">5º. </span>A
                comissão de Ética e Defesa
                profissional será integrada
                exclusivamente por sócios
                titulares da SNNO.
              </p>
              <p
                class="p76 ft2"
              >
                <span
                  class="ft6"
                >Art. 64. </span>As Comissões permanentes, cuja
                composição, funcionamento e
                atribuições estão detalhadas no
                RI - Regimento Interno são: I -
                Comissão de Ética e Defesa
                profissional;
              </p>
              <p
                class="p11 ft3"
              >
                II - Comissão de Incentivo à
                pesquisa Científica;
              </p>
              <p
                class="p77 ft2"
              >
                <span class="ft7">III</span><span
                  class="ft8"
                >– Comissão de Cursos e
                  Congresso Norte e Nordeste
                  de Oftalmologia; IV –
                  Comissão de prevenção à
                  Cegueira e Defesa da Saúde
                  Ocular;</span>
              </p>
              <p
                class="p78 ft2"
              >
                V - Comissão de divulgação e
                Marketing; VI – Comissão de
                Defesa à Saúde Ocular; VII –
                Comissão de Oftalmologia Jovem;
                VIII – Comissão de Esporte,
                Lazer e Cultura.
              </p>
              <p class="p38 ft9">
                Capítulo IV
              </p>
              <p
                class="p79 ft9"
              >
                Receita, patrimônio e
                Escrituração Contábil
              </p>
              <p
                class="p40 ft3"
              >
                <span
                  class="ft9"
                >Art. 65. </span>Constitui receita da SNNO:
              </p>
              <p
                class="p19 ft3"
              >
                I - Anuidade dos associados;
              </p>
              <p
                class="p23 ft2"
              >
                <span class="ft3">II</span><span
                  class="ft4"
                >- Doações, legados,
                  auxílios, subvenções,
                  prêmios, contribuições e
                  aquisições advindas de
                  qualquer pessoa física ou
                  jurídica, seja pública ou
                  privada, nacional ou
                  estrangeira;</span>
              </p>
              <p
                class="p11 ft3"
              >
                III - Resultado líquido
                proveniente de suas atividades
                estatutárias, como cursos,
                simpósios, congressos e
                outros;
              </p>
              <p
                class="p28 ft2"
              >
                IV - prestação de serviços e
                quaisquer outras receitas
                patrimoniais ou eventuais que
                venham a ser auferidas
                decorrentes de suas atividades,
                assim como a locação de bens
                móveis e imóveis e prestação de
                serviços.
              </p>
              <p
                class="p24 ft2"
              >
                <span
                  class="ft6"
                >Art. 66. </span>O patrimônio da SNNO é
                constituído por bens móveis e
                imóveis, bens e legados
                recebidos em doação e o
                resultado líquido proveniente de
                suas atividades estatutárias.
              </p>
              <p
                class="p24 ft2"
              >
                <span
                  class="ft6"
                >parágrafo único. </span>A alienação de bens imóveis é
                decisão da Assembleia Geral,
                ouvida a Diretoria Executiva,
                Conselho Deliberativo e Conselho
                Fiscal.
              </p>
              <p
                class="p4 ft2"
              >
                <span
                  class="ft6"
                >Art. 67. </span>A SNNO aplicará suas rendas,
                recursos e eventuais resultados
                operacionais, integralmente no
                território nacional e na
                manutenção e desenvolvimento de
                seus objetivos institucionais,
                primando pela segurança dos
                investimentos e a manutenção do
                valor real do capital
                aplicado.
              </p>
              <p
                class="p7 ft2"
              >
                <span
                  class="ft6"
                >Art. 68. </span>A escrituração contábil será
                executada de acordo com a
                legislação específica vigente e
                as normas técnicas recomendadas,
                por profissional habilitado, em
                livros revestidos das
                formalidades legais.
              </p>
              <p
                class="p11 ft3"
              >
                <span
                  class="ft9"
                >parágrafo Único. </span>O exercício fiscal coincidirá
                com o ano cível.
              </p>
              <p class="p80 ft9">
                Capítulo V
              </p>
              <p
                class="p81 ft9"
              >
                Alteração Estatutária e
                Dissolução
              </p>
              <p
                class="p12 ft2"
              >
                <span
                  class="ft6"
                >Art. 69. </span>A reforma desse Estatuto, no
                todo ou em parte, somente poderá
                ocorrer com aprovação da
                Assembleia Geral, convocada pelo
                Secretário Geral, de acordo com
                o presente Estatuto, respeitada
                a legislação vigente. Esta
                convocação poderá, também, ser
                publicada nos informativos da
                SNNO ou ser encaminhada aos
                associados por meio dos
                Correios.
              </p>
              <p
                class="p11 ft3"
              >
                <span class="ft9">§</span><span class="ft15">1º. </span>A
                convocação deverá conter as
                alterações pretendidas.
              </p>
              <p
                class="p82 ft2"
              >
                <span class="ft9">§</span><span class="ft21">2º. </span>A
                convocação para alteração do
                Estatuto deverá ocorrer
                mediante: I - proposta da
                Diretoria;
              </p>
              <p
                class="p22 ft3"
              >
                II - proposta de mais de 20%
                (vinte por cento) dos sócios
                titulares quites;
              </p>
              <p
                class="p23 ft2"
              >
                <span class="ft9">§</span><span class="ft11">3º. </span>As alterações propostas deverão
                ser estudadas por comissão
                especial, nomeada pela
                Diretoria, que emitirá parecer
                no prazo de 60 (sessenta)
                dias.
              </p>
              <p
                class="p7 ft2"
              >
                <span
                  class="ft6"
                >Art. 70. </span>para aprovação de alteração
                estatutária é exigido o voto
                concorde de 2/3 (dois terços)
                dos associados com direito a
                voto, presentes à Assembleia
                especialmente convocada para
                esta finalidade, salvo
                disposição legal vigente.
              </p>
              <p
                class="p6 ft2"
              >
                <span class="ft9">§</span><span class="ft16">1º. </span>Não será admitida aprovação de
                alteração no Estatuto em
                primeira convocação, salvo se
                contar com a maioria absoluta
                dos associados.
              </p>
              <p
                class="p11 ft3"
              >
                <span class="ft9">§</span><span class="ft15">2º. </span>Cada associado poderá votar uma
                única vez.
              </p>
              <p
                class="p19 ft3"
              >
                <span class="ft9">§</span><span class="ft15">3º. </span>Não é admitido voto por
                procuração.
              </p>
              <p
                class="p12 ft2"
              >
                <span
                  class="ft6"
                >Art. 71. </span>A impossibilidade de exercer as
                prerrogativas dispostas no
                artigo 1º levará à extinção da
                pessoa jurídica SNNO, por
                deliberação da Assembleia Geral,
                conforme este Estatuto. Nesse
                caso, liquidado o passivo, a
                SNNO destina o eventual
                patrimônio remanescente à
                entidade registrada no CNAS
                (Conselho Nacional de
                Assistência Social) ou entidade
                pública, a critério da CNAS,
                respeitada a lei vigente.
              </p>
              <p class="p38 ft9">
                Capítulo VI
              </p>
            </div>
          </div>
          <div id="page_9">
            <div id="id9_1">
              <p class="p83 ft9">
                Das Eleições
              </p>
              <p
                class="p36 ft2"
              >
                <span
                  class="ft6"
                >Art. 72. </span>No dia seguinte à Solenidade de
                Abertura do Congresso Norte e
                Nordeste de Oftalmologia, no
                período entre nove e quinze
                horas,
                <nobr>realizar-se-á</nobr> a
                eleição da Diretoria Executiva e
                Conselho Fiscal da SNNO.
              </p>
              <p
                class="p6 ft2"
              >
                <span class="ft9">§</span><span class="ft11">1º. </span>Em conformidade com o presente
                Estatuto, o mandato da Diretoria
                Executiva é de dois anos, com
                eventuais variações em função
                das datas de realização do
                Congresso Norte e Nordeste de
                Oftalmologia.
              </p>
              <p
                class="p45 ft2"
              >
                <span class="ft9">§</span><span class="ft16">2º. </span>O
                término de um mandato e o início
                do seguinte ocorrem na
                solenidade de encerramento do
                Congresso Norte e Nordeste de
                Oftalmologia.
              </p>
              <p
                class="p11 ft3"
              >
                <span
                  class="ft9"
                >Art. 73. </span>A eleição acima referida, cujo
                detalhamento
                <nobr>encontra-se</nobr> no RI -
                Regimento Interno, para um
                mandato de dois anos.
              </p>
              <p
                class="p19 ft3"
              >
                <span class="ft9">§</span><span class="ft15">1º. </span>Atuarão como eleitores todos os
                associados no gozo de seus
                direitos.
              </p>
              <p
                class="p23 ft2"
              >
                <span class="ft9">§</span><span class="ft20">2º. </span>Excepcionalmente, sendo
                suspensa a realização do
                Congresso, o mandato fica
                automaticamente prorrogado por
                sessenta dias, para que se
                organize outro processo
                eleitoral detalhado no RI -
                Regimento Interno.
              </p>
              <p
                class="p11 ft3"
              >
                <span class="ft9">§</span><span class="ft15">3º. </span>A
                posse dos eleitos acontecerá na
                solenidade de encerramento do
                evento.
              </p>
              <p
                class="p19 ft3"
              >
                <span
                  class="ft9"
                >Art. 74. </span>As eleições serão realizadas de
                02 (dois) em 02 (dois) anos, no
                mês de novembro, na Assembleia
                Geral Ordinária.
              </p>
              <p
                class="p40 ft3"
              >
                <span class="ft9">§</span><span class="ft15">1º. </span>A
                votação será secreta.
              </p>
              <p
                class="p19 ft3"
              >
                <span class="ft9">§</span><span class="ft15">2º. </span>O
                sócio titular deverá se
                identificar, assinar a folha de
                votação e receber uma cédula
                assinada pelo presidente da
                mesa.
              </p>
              <p
                class="p84 ft2"
              >
                <span class="ft9">§</span><span class="ft20">3º. </span>O
                Secretário da mesa deverá fazer
                uma ata de votação que será
                entregue ao presidente da
                Assembleia Geral juntamente com
                a urna que deverá ser lacrada,
                logo após o término da votação;
                podendo ser utilizada urna
                eletrônica de acordo com a
                disponibilidade do Tribunal
                regional Eleitoral.
              </p>
              <p
                class="p6 ft2"
              >
                <span class="ft9">§</span><span class="ft20">4º. </span>A
                apuração deverá ser feita pelo
                presidente da Assembleia Geral,
                que para tanto convocará, dentre
                os presentes, tantos sócios
                titulares quanto achar
                necessário para
                <nobr>ajudá-lo,</nobr> não
                podendo, no entanto, ser membro
                das chapas concorrentes;
              </p>
              <p
                class="p11 ft3"
              >
                <span class="ft9">§</span><span class="ft15">5º. </span>Os vencedores serão proclamados
                no final da apuração, e a posse
                será no dia marcado pelo
                estatuto.
              </p>
              <p
                class="p34 ft2"
              >
                <span class="ft9">§</span><span class="ft11">6º. </span>Todas as dúvidas e pendências
                deverão ser resolvidos pela
                Assembleia Geral que é soberana,
                não cabendo sob nenhuma hipótese
                recursos à justiça comum.
              </p>
              <p
                class="p7 ft2"
              >
                <span
                  class="ft6"
                >Art. 75. </span>A eleição, quando apresentada
                apenas uma chapa, será por
                aclamação, durante a Assembleia
                Geral Ordinária convocada a cada
                02 (dois) anos;
              </p>
              <p
                class="p7 ft2"
              >
                <span
                  class="ft6"
                >Art. 76. </span>Quando for registrada mais de
                uma chapa, os trabalhos
                eleitorais serão conduzidos por
                comissão de 02 (dois) membros
                (presidente e Secretário)
                designados pela Diretoria
                Executiva e um fiscal, indicado
                por cada chapa concorrente.
              </p>
              <p
                class="p7 ft2"
              >
                <span
                  class="ft6"
                >Art. 77. </span>para concorrer aos cargos da
                Diretoria Executiva e do
                Conselho Fiscal, é necessário
                cumprir o que candidato esteja
                em pleno gozo de seus
                direitos.
              </p>
              <p
                class="p9 ft2"
              >
                <span
                  class="ft6"
                >Art. 78. </span>Não poderão se candidatar aos
                cargos da Diretoria Executiva e
                Conselho Fiscal da SNNO os
                sócios que litiguem
                judicialmente, no polo ativo ou
                passivo, contra a Sociedade.
              </p>
              <p
                class="p24 ft2"
              >
                <span
                  class="ft6"
                >Art. 79. </span>O corpo de votantes será
                constituído exclusivamente pelos
                sócios titulares, no pleno
                exercício de seus direitos e
                quites com a tesouraria.
              </p>
              <p
                class="p7 ft2"
              >
                <span
                  class="ft6"
                >Art. 80. </span>A apresentação das candidaturas
                para os cargos mencionados
                deverá ser feita por meio de
                ofício dirigido ao Secretário
                Geral, 20 (vinte) dias antes da
                Solenidade de Abertura referida
                no artigo 69.
              </p>
              <p
                class="p11 ft3"
              >
                <span class="ft9">§</span><span class="ft15">1º. </span>Esse ofício deverá estar
                acompanhado de certidão negativa
                de débitos de cada candidato.
              </p>
              <p
                class="p19 ft3"
              >
                <span class="ft9">§</span><span class="ft15">2º. </span>Certidão negativa de que cada
                candidato não litiga
                judicialmente, nos polos ativo
                e/ou passivo, contra a SNNO.
              </p>
              <p
                class="p85 ft2"
              >
                <span
                  class="ft6"
                >Art. 81. </span>Os candidatos a Tesoureiro e
                membros do Conselho Fiscal
                deverão apresentar certidão
                negativa obtida junto à Receita
                Federal.
                <span
                  class="ft6"
                >Art. 82. </span>O presidente e o
                <nobr>Vice-presidente</nobr> não
                poderão ser eleitos para os
                mesmos cargos no mandato
                subsequente.
              </p>
              <p
                class="p8 ft2"
              >
                <span
                  class="ft6"
                >parágrafo único. </span>Em caso de renúncia, abandono
                de cargo ou destituição dos
                integrantes da Diretoria, esses
                associados não poderão ocupar
                cargo algum no mandato
                subsequente.
              </p>
              <p
                class="p11 ft3"
              >
                <span
                  class="ft9"
                >Art. 83. </span>Em caso de vacância, a
                presidência será ocupada pelo
                <nobr>Vice-presidente</nobr> até
                o término do mandato.
              </p>
              <p
                class="p86 ft2"
              >
                <span
                  class="ft6"
                >Art. 84. </span>Em caso de vacância da
                presidência e da
                <nobr>vice-presidência,</nobr> o
                Secretário Geral assume os dois
                cargos e convoca,
                extraordinariamente, a
                Assembleia Geral para eleger os
                ocupantes dos cargos vagos, o
                que deverá ocorrer,
                improrrogavelmente, até 60
                (sessenta) dias após
                vacância.
              </p>
              <p class="p87 ft9">
                Capítulo XII
              </p>
              <p
                class="p50 ft9"
              >
                Disposições Gerais
              </p>
              <p
                class="p5 ft2"
              >
                <span
                  class="ft6"
                >Art. 85. </span>Os integrantes da Diretoria
                Executiva, integrantes das
                Comissões, bem como os
                associados não responderão, nem
                subsidiariamente, pelos
                compromissos assumidos pela
                SNNO.
              </p>
              <p
                class="p8 ft2"
              >
                <span
                  class="ft6"
                >Art. 86. </span>A SNNO não remunera seus
                dirigentes, mantenedores ou
                associados e não distribui
                resultados, dividendos,
                bonificações, participações ou
                parcela de seu patrimônio, sob
                forma ou pretexto algum.
              </p>
              <p
                class="p11 ft3"
              >
                <span
                  class="ft9"
                >Art. 87. </span>A SNNO é regida pelo presente
                Estatuto, regulamentado pelo
                Regimento Interno - RI.
              </p>
              <p
                class="p86 ft2"
              >
                <span
                  class="ft6"
                >Art. 88. </span>Esse Estatuto poderá ser
                frequentemente complementado
                pela Diretoria Executiva da SNNO
                por meio de alterações do RI -
                Regimento Interno.
              </p>
              <p
                class="p10 ft14"
              >
                <span
                  class="ft1"
                >Art. 89. </span>Os casos omissos neste Estatuto
                e no RI - Regimento Interno
                serão resolvidos pela Diretoria
                Executiva, ouvido o Conselho
                Deliberativo, atendidos os
                dispositivos legais.
              </p>
            </div>
          </div>
          <div id="page_10">
            <div id="id10_1">
              <p
                class="p11 ft3"
              >
                <span
                  class="ft9"
                >Art. 90. </span>A presente reforma consolidada
                entra em vigor na data do seu
                registro em cartório.
              </p>
            </div>
          </div>
        </row>
      </div>
      <div
        id="col2"
        class="col-lg-6 txtFormatSend col1"
        style="border-style: groove; margin-bottom: 10px;"
      >
        <h2
          style="text-align: center; font-size: 32px; margin-top: 0px !important;"
        >
          Sugestões enviadas
        </h2>
        <p>Olá, colegas!</p>
        <p>Começarei tentando contribuir com uma revisão das pontuações e, na medida do possível, opinando.</p>
        <p>* No Art. 1º, consta: "fundada em 1º de março de 1963. No Estatuto vigente, consta: 18 de outubro de 1981. Não tenho como confrontar estas informações.</p>
        <p>* Art. 1º</p>
        <p>* ... "à" pesquisa científica, "ao"desenvolvimento...</p>
        <p>* Art. 1º.</p>
        <p>III - resguardar o exercício da Oftalmologia e representar os oftalmologistas ... Pergunto: onde se inseririam os interesses dos oftalmologistas mediante as suas pessoas jurídicas? há algum Artigo q explicite esta nova situação com legitimidade para tanto?</p>
        <p>V - Seria neste Artigo? " representar os seus "associados", pessoas físicas ou jurídicas, "judicial e extrajudicialmente" ...</p>
        <p>Vll - ... Especialista em "Oftalmologia"...</p>
        <p>Vlll - ... obediência a "este" Estatuto...</p>
        <p>... , ds Associação Médica Brasileira e "do" Conselho...</p>
        <p>IX ... incentivar ... e para "a" classe médica...</p>
        <p>XV - promover reuniões.. em sua sede"," assim ...(vírgula depois de sede)</p>
        <p>XVII - ... ligadas "à" saúde...</p>
        <p>------------------------------------------------------------------------------------</p>
        <p>* Art. 2º. </p>
        <p>lll - ... composto por cinco membros.</p>
        <p>obs: por convenção, até o numeral 9, escreve-se por extenso: nove. A partir de 10, deve-se esvrevê-lo assim: 10.  Não veria nenhum problema se a partir de 10, acrescentássemos, também, entre parênteses: (dez). </p>
        <p>Contudo, manter-se tudo como está não comprometeria absolutamente nada</p>
        <p>-----------------------------------------------------------------------------------</p>
        <p>*  Art. 6º. § 2º...especialidade "há" mais de 15 ...</p>
        <p>------------------------------------------------------------------------------------</p>
        <p>* Art. 7º. Sócios Aspirantes "Alunos". Haveria a necessidade de explicitar-se : "Alunos"? Na designação dos sócios (Art. 3º) não consta Aspirante Aluno, mas tão somente: "Aspirante".</p>
        <p>------------------------------------------------------------------------------------</p>
        <p>* Art. 9º. O título... pela diretoria "aqueles"... (sem a crase)</p>
        <p>------------------------------------------------------------------------------------</p>
        <p>** Desde o Art. "10" até o Art. 90 não se manteve o padrão: 10º ... 90º</p>
        <p>------------------------------------------------------------------------------------</p>
        <p>* Art . 15º, </p>
        <p>V. § 3º. ... reembolsados por "nenhuma" contribuição que "tenham </p>
        <p>realizado" ou que ...  </p>
        <p>§ 4º. A qualquer tempo...do quadro social ","por meio...</p>
        <p>§ 5º... serão excluídos do quadro de associados","...aqueles "que </p>
        <p>infringirem"... (tirar a vírgula depois de "que")</p>
        <p>...Defesa Profissional e "da"anuência...</p>
        <p>§ 6º Na eventualidade "de o" associado... (tirar:"do")</p>
        <p>------------------------------------------------------------------------------------</p>
        <p>* Art. 16º.</p>
        <p>lV - ...em faltas graves ou "aos"autores de faltas gravíssimas contra "a"ética ...</p>
        <p>------------------------------------------------------------------------------------</p>
        <p>* Artigo 17º. Serão excluídos da SNNO, os sócios que:</p>
        <p>I - não contribuirem com a anuidade por três anos consecutivos ou cinco anos alternados.</p>
        <p>Sugestão: retirar este ítem I, haja vista os sócios inadimplentes já não gozarem dos benefícios daqueles adimplentes. Desde q voltem a contribuir, não vejo porque excluir um associado, tão somente por uma questão financeira. As finanças são importantes, mas a Sociedade não visa ao lucro. Cabe à diretoria convencer os seus associados a contribuirem regularmente. É possível q a maioria pense diferente. Fica, então, a minha opinião.</p>
        <p>------------------------------------------------------------------------------------</p>
        <p>*Art. 18º. Os demais casos...e Defesa Profissional, sendo "garantida" </p>
        <p>ao acusado a ampla...</p>
        <p>§ 1º. No curso das sindicâncias e "do" processo...</p>
        <p>...expedidas pelo Conselho Federal "de" Medicina.</p>
        <p>-----------------------------------------------------------------------------------</p>
        <p>* Art. 19º. ... requerimento do excluído"," com apresentação...</p>
        <p>------------------------------------------------------------------------------------</p>
        <p>* Art. 24º.</p>
        <p>l - eleger a Diretoria da SNNO","de acordo com...</p>
        <p>ll - destituir a Diretoria Executiva da SNNO, conjunta ou individual, de acordo com a legislação vigente e com o RI - Regimento Interno, todas as vezes que forem:</p>
        <p>a) negligenciadas... as finalidades da "Instituição" e o disposto...</p>
        <p>b) , devidamente "apuradas"em processo...</p>
        <p>lll - ... pelo Tesoureiro"," e aprovados...</p>
        <p>Parágrafo único. ... de Diretores"," nos termos...</p>
        <p>...a Assembleia Geral (tirar vírgula) decidirá...</p>
        <p>------------------------------------------------------------------------------------</p>
        <p>* Art. 26º... a extinção da SNNO ","as decisões...</p>
        <p>... com direito a voto"," em primeira convocação e 1/3 (um terço)","nas demais convocações.</p>
        <p>------------------------------------------------------------------------------------</p>
        <p>* Art. 28º. As Assembleias... Na ausência destes "," a Assembleia ...</p>
        <p>------------------------------------------------------------------------------------</p>
        <p>* Art. 30º. Sugiro substituir o "ou" por "e". Isto porque somente uma informação fixada na sede não alcançaria a maioria dos associados. </p>
        <p>... A Assembleia Geral...Edital fixado na sede social da SNNO "e"enviado a todos os associados, via postal ou correio eletrônico,...</p>
        <p>------------------------------------------------------------------------------------ </p>
        <p>* Art. 31º. ... É "garantida" aos associados ... a prerrogativa...</p>
        <p>§ 1º. Ao assinalar a lista de presença"," o associado deverá...</p>
        <p>§ 2º. De posse desta lista","a Diretoria Executiva providenciará...(não tem a vírgula depois de Executiva)</p>
        <p>§ 3º. Na Assembleia convocada pelos associados","deverão estar presentes...dos que "a"convocaram, ...</p>
        <p>------------------------------------------------------------------------------------</p>
        <p>* Art. 32º. No ano em que for realizada a eleição, a Assembleia...no mês de novembro, de acordo...</p>
        <p>§ 1º. ... com antecedência  mínima de "15 (quinze)"dias.</p>
        <p>Obs: eram 30 dias no Estatuto vigente?</p>
        <p>... A juízo da Diretoria"," a referida convocação poderá ser feita através do site oficial da SNNO, "do" correio eletrônico ou "de"carta registrada","obedecendo ao mesmo prazo.</p>
        <p>§ 2º. se fato relevante ... todas as providências para que "ela" se realize...(substituir "a mesma" por ela: lembrando-se de q "mesma" não é pronome...).</p>
        <p>------------------------------------------------------------------------------------</p>
        <p>* Art. 33º. Extraordinariamente","a Assembleia poderá ser convocada pelo Presidente","quando julgá-la necessária"," ou mediante um requerimento de (20) vinte sócios titulares ...de seus direitos"," "os" quais deverão se dirigir...</p>
        <p>------------------------------------------------------------------------------------</p>
        <p>* Art. 36º. ... enviado por correio eletrônico, assegurando-se o Presidente "de" que os sócios...</p>
        <p>----------------------------------------------------------------------------------</p>
        <p>* Art. 37º. O associado... a lista de presença"," que servirá...</p>
        <p>------------------------------------------------------------------------------------ </p>
        <p>* Art. 38º. Obs: suprimir "4" e "02"; manter "quatro" e "dois", se a opção for a regra formal.</p>
        <p>------------------------------------------------------------------------------------</p>
        <p>*Art. 40º. ...não auferirão "nenhuma vantagem" "financeira"...</p>
        <p>------------------------------------------------------------------------------------</p>
        <p>* Art. 41º. ... qualquer cargo da Diretoria, os membros...</p>
        <p>------------------------------------------------------------------------------------</p>
        <p>* Art. 44º. </p>
        <p>ll - dirigir ... (d minúsculo)</p>
        <p>Vll - responsabilizar-se pela administração e "pelo" funcionamento...</p>
        <p>Vlll - substituir...</p>
        <p>lX - em conjunto...</p>
        <p>X - receber...</p>
        <p>Xl - analisar...</p>
        <p>------------------------------------------------------------------------------------*</p>
        <p>Art. 45º. </p>
        <p>ll - substituir o Secretário Geral, em suas faltas e "em seus" impedimentos, e auxiliá-lo...</p>
        <p>------------------------------------------------------------------------------------</p>
        <p>* Art. 46º. </p>
        <p>l - organizar"," de acordo...da Diretoria"," "a" órdem do Dia...</p>
        <p>------------------------------------------------------------------------------------</p>
        <p>* Art. 47º. </p>
        <p>Vl - (Sugestão): ...prestar contas do seu movimento financeiro ao Conselho Fiscal, no prazo máximo de 90 (noventa) dias, contados a partir do encerramento de cada evento;</p>
        <p>Vlll - promover a cobrança... (tirar a vírgula depois de promover).</p>
        <p>----------------------------------------------------------------------------------</p>
        <p>* Art. 50º. A Diretoria Executiva será assessorada pelas Comissões nomeadas pelo Presidente.</p>
        <p>§ 1º.... a qualquer momento"," a critério ...</p>
        <p>------------------------------------------------------------------------------------</p>
        <p>* Art. 53º. </p>
        <p>§ 4º. ... do mencionado "Conselho" ( C maiúsculo).</p>
        <p>-----------------------------------------------------------------------------------</p>
        <p>* Art. 54º.</p>
        <p>l - deliberar em conjunto com a Diretoria sobre... (sem vírgula após Diretoria);</p>
        <p>Vl -  apreciar matéria "para" cujo exame "for"convocado;</p>
        <p>---------------------------------------------------------------------------------</p>
        <p>* Art. 55º.</p>
        <p>§ 2º. ... atividades científicas do evento"," nem com o ...</p>
        <p>------------------------------------------------------------------------------------</p>
        <p>* Art. 59º. ... pelo 1º Secretário e em caso de "sua" ausência"," ou "de seu" impedimento","por um Conselheiro...</p>
        <p>------------------------------------------------------------------------------------</p>
        <p>* Art. 63. </p>
        <p>§ 2º. ... da Diretoria Executiva e "do" Conselho Deliberativo...</p>
        <p>§ 4º. ... a qualquer momento","a critério do ...</p>
        <p>------------------------------------------------------------------------------------</p>
        <p>* Art. 64º. </p>
        <p>O íten lV, trata da "Comissão de Prevenção à Cegueira e Defesa da Saúde Ocular. O ítem V, trata da Comissão  de Defesa à Saúde Ocular. É isto mesmo? As "Defesas" não são conflitantes?</p>
        <p>------------------------------------------------------------------------------------</p>
        <p>* Art. 72º. ... da Diretoria Executiva e "do" Conselho Fiscal...</p>
        <p>------------------------------------------------------------------------------------</p>
        <p>* Art. 73º. a eleição acima referida... Regimento Interno "," "é" para um mandato...</p>
        <p>------------------------------------------------------------------------------------</p>
        <p>* Art. 74º. </p>
        <p>§ 3º. O Secretário da mesa ... com a disponibilidade do Tribunal "Regional" Eleitoral.</p>
        <p>§ 5º. no final da apuração e a posse será no dia marcado, "conforme" "o" Estatuto. (sem vírgula após apuração).</p>
        <p>------------------------------------------------------------------------------------</p>
        <p>* Art. 77º.... e do Conselho Fiscal, é "necessária a observância de que o" candidato esteja...</p>
        <p>------------------------------------------------------------------------------------</p>
        <p>* Art. 78º. ... da Diretoria Executiva e "do" Conselho Fiscal...</p>
        <p>------------------------------------------------------------------------------------</p>
        <p>* Art. 82º. </p>
        <p>Parágrafo único: Em caso de renúncia, ...não poderão ocupar "nenhum cargo" no mandato subsequente.</p>
        <p>------------------------------------------------------------------------------------</p>
        <p>* Art. 84º. ..., até 60 (sessenta) dias após "a" vacância.</p>
        <p>------------------------------------------------------------------------------------</p>
        <p
          v-for="suggestion in suggestions[0]"
          :key="suggestion.id"
          style="word-wrap: break-word;"
        >
          <u><b># {{ suggestion.user.name }}:</b></u> <br>
          {{ suggestion.text }}
        </p>
      </div>
    </div>
    <!-- PARTICIPE -->
    <div class="row">
      <div
        id="col2"
        class="col-lg-12 txtFormatSend "
        style="border-style: groove; margin-bottom: 10px;"
      >
        <h2
          style="text-align: center; font-size: 32px; margin-top: 0px !important;"
        >
          participe!
        </h2>
        <p>
          participe da elaboração do novo estatuto da
          Sociedade Norte Nordeste de Oftalmologia e
          contribua com propostas que tornem a sua
          entidade mais forte e aguerrida na defesa da
          classe dos Médicos Oftalmologistas.
        </p>
        <p>
          A Assembleia Geral Extraordinária será
          realizada no Dia 15 às 19:30h 1ª chamada
        </p>
        <p>
          Leia o texto base com atenção e, se desejar
          acrescentar ou sugerir alguma mudança,
          preencha o espaço abaixo:
        </p>

        <div id="text-msg">
          <textarea
            id="textarea"
            v-model="form.text"
            class="form-control"
            placeholder="Digite sua sugestão..."
            rows="3"
            max-rows="6"
          />
          <div v-if="form.text == ''">
            <button
              style="background-color: #6C757D; color: white;"
              type="button"
              variant="success"
              class="btn btn-block"
            >
              Enviar
            </button>
          </div>
          <div v-else>
            <button
              type="button"
              variant="secondary"
              class="btn btn-block btn-success"
              @click="createSuggestion()"
            >
              Enviar
            </button>
          </div>
          <br>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import SuggestionsServices from './suggestionsServices'

export default {
  components: {
  },
  data() {
    return {
      statuto: {},
      overlay: true,
      showAll: false,
      sucess: false,
      form: {
        text: '',
      },
      suggestions: [],
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.sessionInfo.user
    },
  },
  beforeCreate() {
    this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  },
  mounted() {
    SuggestionsServices().getSuggestionsValid().then((api) => {
      this.suggestions.push(api)
      console.log(this.suggestions)
    })
  },
  methods: {
    ...mapActions('statute', [
      'ActionSetNewSuggestions',
    ]),
    async createSuggestion() {
      try {
        const response = await SuggestionsServices().createSuggestion(this.form)
        // eslint-disable-next-line no-unused-expressions
        window.location.reload()
      } catch (err) {
        console.log('...')
      }
      this.sucess = true
      this.form.text = ''
    }

  },
}
</script>

<style scoped>
.col1{
  overflow-y: scroll;
  background-color: #cfcfcf;
  height: 440px;
}
#col1 {
    overflow-y: scroll;
    background-color: #cfcfcf;
    height: 440px;
}

#col2 {
    min-height: 440px;
    font-size: 18px;
}
@media only screen and (max-width: 600px) {
    #col2 {
        min-height: 670px;
    }
    #text-msg {
        margin-bottom: 15px;
    }
}
#text-msg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
.txtFormat {
    font-family: 'Roboto-Medium';
    font-size: 18px;
    color: #2c3e50;
}
.txtFormatSend {
    font-family: 'Roboto-Medium';
    font-size: 16px;
    color: #2c3e50;
}
.txtStream {
    font-family: 'Roboto';
    font-size: 13px;
    color: #838687;
}
.numberStream {
    color: #3787bb;
    font-size: 12px;
}
body {
    background: #f6f6f6 !important;
}
.panel {
    height: 100% !important;
    background-color: #f6f6f6 !important;
    border: none;
}
.c-loader {
    animation: is-rotating 1s infinite;
    border: 6px solid #e5e5e5;
    border-radius: 50%;
    border-top-color: #51d4db;
    height: 50px;
    width: 50px;
}
.titleText {
    font-size: 50px;
    line-height: 53px;
    color: #737373;
    font-weight: 400;
    font-family: 'Roboto';
}

@keyframes is-rotating {
    to {
        transform: rotate(1turn);
    }
}
#title {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}
#card-base {
    margin-bottom: 50px;
}
</style>
